import type { FC } from 'react'

const ArrowUpKeyIcon: FC<{ className?: string }> = props => {
  const { className } = props
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.00012 1.64508e-06C2.68641 1.78993e-06 0.000121808 2.68629 0.000121808 6L0.000121808 13.9999C0.000121808 17.3136 2.68641 19.9999 6.00012 19.9999L14.0001 19.9999C17.3138 19.9999 20.0001 17.3136 20.0001 13.9999L20.0001 6C20.0001 2.68629 17.3138 1.15055e-06 14.0001 1.29539e-06L6.00012 1.64508e-06ZM6.95687 9.56483C6.67121 9.26488 6.68279 8.79015 6.98273 8.50448L9.47993 6.1262C9.53692 6.07128 9.60058 6.02711 9.66831 5.99373C9.69624 5.97996 9.72484 5.96803 9.75392 5.95795C9.81596 5.93646 9.8802 5.92337 9.9449 5.91864C9.95734 5.91773 9.9698 5.91714 9.98227 5.91685L10.0025 5.91665C10.1164 5.91701 10.2244 5.94279 10.321 5.9886C10.382 6.01748 10.4399 6.05503 10.4928 6.10121C10.5032 6.11027 10.5133 6.11961 10.5232 6.12923L13.0172 8.50449C13.3171 8.79015 13.3287 9.26488 13.0431 9.56483C12.7574 9.86478 12.2827 9.87636 11.9827 9.59069L10.75 8.4167L10.75 13.3333C10.75 13.7475 10.4142 14.0833 10 14.0833C9.58581 14.0833 9.25003 13.7475 9.25003 13.3333L9.25003 8.41659L8.01722 9.59069C7.71727 9.87635 7.24254 9.86478 6.95687 9.56483Z"
        fill="#94A3B8"
      />
    </svg>
  )
}

export default ArrowUpKeyIcon
