import type { FC } from 'react'
import { Meta } from '@/@types/meta'
import { Seo } from './Seo'
import { Navigation } from './navigation/Navigation'


export const Layout: FC<{ meta?: Meta }> = props => {
  const { meta, children } = props
  return (
    <>
      <Seo meta={meta} />
      <div className="mx-auto w-full overflow-hidden">
        {/* [MAIN CONTENT] */}
        <div className="grid grid-cols-1 md:grid-cols-[max-content_1fr]">
          <Navigation />
          {children}
        </div>
      </div>
    </>
  )
}
