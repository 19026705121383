import type { FC } from 'react'
import { Meta } from '@/@types/meta'
import Head from 'next/head'

export const Seo: FC<{ meta?: Meta }> = ({ meta }) => {
  const title = meta?.title ? `${meta.title} - Teero` : 'Teero'
  return (
    <Head>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta property="og:url" content={meta?.ogUrl} />
      <meta name="image" property="og:image" content={meta?.ogImage} />
      <link rel="apple-touch-icon" href="/logo192.png" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
    </Head>
  )
}
