import type { FC } from 'react'

interface GithubIconProps {
  className?: string
}

const GithubIcon: FC<GithubIconProps> = props => {
  const { className } = props
  return (
    <svg
      className={className}
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.66391 16.1666H5.9451V15.2841C5.9451 15.2841 5.93061 13.9033 6.68961 12.7362C4.9595 12.6153 3.58632 11.0717 3.58632 9.16613C3.58632 8.49742 3.84017 7.87387 4.27547 7.3372L4.31976 5.69057C4.33084 5.27231 4.7491 4.98694 5.14265 5.1292L6.91024 5.76809C7.40943 5.64372 7.94354 5.57302 8.50065 5.57302C9.05776 5.57302 9.59187 5.64372 10.0911 5.76894L11.8587 5.13005C12.2522 4.98779 12.6705 5.27316 12.6815 5.69142L12.7258 7.3372C13.1603 7.87387 13.415 8.49742 13.415 9.16613C13.415 11.0717 12.0418 12.6153 10.3117 12.7362C11.0707 13.9033 11.0562 15.2841 11.0562 15.2841V16.1666H12.3374C14.4525 16.1666 16.1673 14.4519 16.1673 12.3376V4.66324C16.1673 2.54809 14.4525 0.833313 12.3374 0.833313H4.66732C2.55047 0.833313 0.833984 2.54979 0.833984 4.66665V12.3367C0.833984 14.4519 2.54876 16.1666 4.66391 16.1666"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
export default GithubIcon
