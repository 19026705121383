import type { FC } from 'react'

interface DownIconProps {
  className?: string
}

const DownIcon: FC<DownIconProps> = props => {
  const { className } = props
  return (
    <svg
      className={className}
      viewBox="0 0 8 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.666342 0.333331L3.99967 3.66666L7.33301 0.333332"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default DownIcon
