import type { FC } from 'react'

interface FilterIconProps {
  className?: string
}

const FilterIcon: FC<FilterIconProps> = props => {
  const { className } = props
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5119 12.1548C15.1628 12.8057 15.1628 13.861 14.5119 14.5118C13.8611 15.1627 12.8058 15.1627 12.1549 14.5118C11.504 13.861 11.504 12.8057 12.1549 12.1548C12.8058 11.5039 13.8611 11.5039 14.5119 12.1548"
        stroke="#1E3A8A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.84518 5.48815C8.49605 6.13903 8.49605 7.19431 7.84518 7.84518C7.19431 8.49605 6.13903 8.49605 5.48815 7.84518C4.83728 7.19431 4.83728 6.13903 5.48815 5.48815C6.13903 4.83728 7.19431 4.83728 7.84518 5.48815"
        stroke="#1E3A8A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 13.3333H11.6667"
        stroke="#1E3A8A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.33325 6.66667H14.9999"
        stroke="#1E3A8A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default FilterIcon
