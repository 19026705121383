import type { FC } from 'react'


const OverviewIcon: FC<{ className: string }> = props => {
  const { className } = props

  return (
    <svg
      width={24}
      height={24}
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.747 6.187C19.663 9.103 19.663 13.831 16.747 16.747C13.831 19.663 9.103 19.663 6.187 16.747C3.271 13.831 3.271 9.103 6.187 6.187C9.103 3.271 13.831 3.271 16.747 6.187"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.93005 12.89V11.89"
        stroke="currentColor"
        className="text-blue-900"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.25 12.89V10.89"
        stroke="currentColor"
        className="text-blue-900"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5699 12.89V9.89001"
        stroke="currentColor"
        className="text-blue-900"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 20L16.75 16.75"
        stroke="#1E293B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default OverviewIcon
