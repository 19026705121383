import type { FC } from 'react'

interface LinkIconProps {
  className?: string
}
const LinkIcon: FC<LinkIconProps> = props => {
  const { className } = props
  return (
    <svg
      className={className}
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0143 8.01267L13.1681 6.85886C13.9058 6.14324 14.2006 5.08571 13.9396 4.09168C13.6786 3.09766 12.9023 2.32135 11.9083 2.06037C10.9143 1.79939 9.85674 2.09423 9.14112 2.83185L6.67343 5.29954C6.13917 5.83343 5.83899 6.55776 5.83899 7.31305C5.83899 8.06834 6.13917 8.79267 6.67343 9.32655L6.66609 9.33389C6.89594 9.55937 7.16282 9.74368 7.45509 9.87878"
        stroke="#3B82F6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.53753 6.1279C8.82982 6.26324 9.0967 6.44778 9.32652 6.67346C9.86079 7.20734 10.161 7.93167 10.161 8.68696C10.161 9.44226 9.86079 10.1666 9.32652 10.7005L6.85883 13.1682C5.74203 14.2517 3.96234 14.2382 2.86207 13.1379C1.7618 12.0376 1.74833 10.258 2.83182 9.14115L3.98563 7.98734"
        stroke="#3B82F6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default LinkIcon
