import type { FC } from 'react'

const InfoIcon: FC<{ className: string }> = props => {
  const { className } = props
  return (
    <svg
      className={className}
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="presentation"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3C16.971 3 21 7.029 21 12C21 16.971 16.971 21 12 21C7.029 21 3 16.971 3 12C3 7.029 7.029 3 12 3Z"
        fill="#1E40AF"
      />
      <path
        d="M12 16.5V11.5"
        stroke="#EFF6FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.999 7.75C11.861 7.75 11.749 7.862 11.75 8C11.75 8.138 11.862 8.25 12 8.25C12.138 8.25 12.25 8.138 12.25 8C12.25 7.862 12.138 7.75 11.999 7.75"
        stroke="#EFF6FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default InfoIcon
