import type { FC } from 'react'

const ThreeDotsIcon: FC<{ className?: string }> = props => {
  const { className } = props
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 4 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M2.00004 14.5027C1.72378 14.5027 1.49983 14.2788 1.49983 14.0025C1.49983 13.7262 1.72378 13.5023 2.00004 13.5023C2.27629 13.5023 2.50024 13.7262 2.50024 14.0025C2.50024 14.2788 2.27629 14.5027 2.00004 14.5027"
        stroke="#1E293B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.00004 8.5002C1.72378 8.5002 1.49983 8.27625 1.49983 7.99999C1.49983 7.72374 1.72378 7.49979 2.00004 7.49979C2.27629 7.49979 2.50024 7.72374 2.50024 7.99999C2.50024 8.27625 2.27629 8.5002 2.00004 8.5002"
        stroke="#1E293B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.00004 2.4977C1.72378 2.4977 1.49983 2.27375 1.49983 1.99749C1.49983 1.72123 1.72378 1.49728 2.00004 1.49728C2.27629 1.49728 2.50024 1.72123 2.50024 1.99749C2.50024 2.27375 2.27629 2.4977 2.00004 2.4977"
        stroke="#1E293B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
export default ThreeDotsIcon
