import type { FC } from 'react'

const ArrowRightIcon: FC<{ className?: string }> = props => {
  const { className } = props
  return (
    <svg
      width={16}
      height={16}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.667 8H3.333M9.333 11.333 12.667 8M9.333 4.667 12.667 8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
export default ArrowRightIcon
