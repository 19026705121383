import type { FC } from 'react'

const BriefcaseIcon: FC<{ className: string }> = props => {
  const { className } = props
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.6 8.8V5.6C15.6 4.71634 14.8837 4 14 4H10C9.11637 4 8.40002 4.71634 8.40002 5.6V8.8"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x={4}
        y="8.79999"
        width={16}
        height="11.2"
        rx="1.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default BriefcaseIcon
