import type { FC } from 'react'
import { useState, useEffect } from 'react'
import { NavigationSectionItemProps } from '@/@types/navigation'
import { GetFirstRoute } from '@/utils/router'
import Link from 'next/link'

export const NavigationSectionItem: FC<NavigationSectionItemProps> = props => {
  const { title, path, count } = props
  const [open, setOpen] = useState<boolean>(false)
  useEffect(() => {
    setOpen(
      () =>
        GetFirstRoute(window.location.pathname) === path ||
        (GetFirstRoute(window.location.pathname) === '/jobs' && path === '/'),
    )
  }, [])

  return (
    <li className="group" {...{ open }}>
      <Link href={path}>
        <a
          className="font-secondary text-light-200 relative block rounded-full p-2 text-sm font-medium leading-6 before:pointer-events-none before:invisible before:absolute before:top-1/2 before:right-4 before:z-10 before:box-content before:block before:h-1.5 before:w-1.5 before:-translate-y-1/2 before:transform before:rounded-full before:border-4 before:border-white before:bg-blue-900 before:opacity-0 before:content-[''] hover:bg-blue-50 group-open:bg-blue-50 group-open:text-blue-900 group-open:before:visible group-open:before:opacity-100"
          onClick={() => setOpen(true)}
        >
          <div className="flex items-center gap-4">
            <props.icon className="group-open:text-slate-800" />
            <div className="flex items-center gap-2">{title}</div>
            {count && (
              <span className="rounded-full bg-teal-500 px-2 text-xs font-medium leading-6 text-white">
                {count}
              </span>
            )}
          </div>
        </a>
      </Link>
    </li>
  )
}
