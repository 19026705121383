export const NullableString = (
  query: string | string[] | undefined,
): string | null => {
  if (query === undefined) {
    return null
  }
  return String(query)
}

export const GetFirstRoute = (route: string) => {
  return '/' + route.split('/')[1]
}
