import type { FC } from 'react'
import { NavigationSectionProps } from '@/@types/navigation'
import { NavigationSectionItem } from './NavigationSectionItem'

export const NavigationSection: FC<NavigationSectionProps> = props => {
  const { title, items } = props
  return (
    <>
      {title && (
        <p className="text-light-300 mb-2 text-xs font-medium leading-6">
          {title.toLocaleUpperCase()}
        </p>
      )}
      <ul className="mb-6 space-y-3">
        {items.map((item, idx) => (
          <NavigationSectionItem key={idx} {...item} />
        ))}
      </ul>
    </>
  )
}
