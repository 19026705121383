import type { FC } from 'react'

interface LinkedInIconProps {
  className: string
}

const LinkedInIcon: FC<LinkedInIconProps> = props => {
  const { className } = props
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        clipRule="evenodd"
        d="M6.25 2.5h7.503A3.747 3.747 0 0 1 17.5 6.247v7.507a3.747 3.747 0 0 1-3.747 3.746H6.247A3.747 3.747 0 0 1 2.5 13.753V6.25A3.75 3.75 0 0 1 6.25 2.5v0Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.767 9.25v4.5M9.766 13.75v-2.625c0-1.036.839-1.875 1.875-1.875v0c1.036 0 1.875.84 1.875 1.875v2.625M6.765 6.532a.188.188 0 1 0 .002.374.188.188 0 0 0-.002-.374"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default LinkedInIcon
