const GreenLine = () => {
    return (
      <svg
        className="absolute right-0 top-1/2 -translate-y-1/2"
        width={2}
        height={24}
        viewBox="0 0 2 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="presentation"
      >
        <line
          x1={1}
          y1={23}
          x2={1}
          y2={1}
          stroke="#14B8A6"
          strokeWidth={2}
          strokeLinecap="round"
        />
      </svg>
    )
  }

  export default GreenLine