import { FC, useEffect } from 'react'
import { useState } from 'react'
import { classNames } from '@utils/classNames'
import { useAuth } from '@hooks/useAuth'
import { signOut } from 'firebase/auth'
import { getInitials } from '@utils/candidates'
import { toCapUpperCase } from '@utils/strings'
import { ThreeDotsIcon } from '../icons'
import { useClickOutside } from '@mantine/hooks'
import { trpc } from '@utils/trpc'

export const Profile = () => {
  const { auth, user } = useAuth()
  const [isMenuHidden, setIsMenuHidden] = useState<boolean>(true)
  const [isMenuTransparent, setIsMenuTransparent] = useState<boolean>(true)
  const ref = useClickOutside(() =>
    !isMenuHidden ? handleThreeDotsClick() : null,
  )

  const {
    data: organisationName,
    isLoading: isOrganisationNameLoading,
    refetch,
  } = trpc.useQuery(['user.organisationNameByUID'], {
    refetchInterval: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: false,
    retryOnMount: false,
    context: {
      skipBatch: true,
    },
  })

  const handleSignOut = () => {
    signOut(auth)
  }

  const handleThreeDotsClick = () => {
    if (isMenuHidden) {
      setIsMenuHidden(false)
      setTimeout(() => {
        setIsMenuTransparent(false)
      }, 5)
    }
    if (!isMenuHidden) {
      setIsMenuTransparent(true)
      setTimeout(() => {
        setIsMenuHidden(true)
      }, 200)
    }
  }

  useEffect(() => {
    if (user) {
      refetch()
    }
  }, [user])

  return (
    <div className="relative px-2">
      <div
        className={classNames(
          isMenuTransparent ? 'opacity-0' : 'opacity-100',
          isMenuHidden ? 'hidden' : 'block',
          'shadow-box group absolute -top-9 right-0 cursor-pointer rounded-md border bg-white py-2 px-5 transition-all duration-200 hover:bg-blue-50',
        )}
        onClick={handleSignOut}
        ref={ref}
      >
        <p className="text-light-200 text-sm font-medium ">Sign Out</p>
      </div>
      <div className="flex items-center justify-between gap-3">
        <div className="grid max-w-full grid-cols-[max-content_1fr] gap-3">
          {user?.photoURL ? (
            <img
              className="block h-12 w-12 rounded-full object-cover"
              src={String(user?.photoURL)}
              alt=""
            />
          ) : (
            <div className="block h-12 w-12 rounded-full bg-indigo-500 object-cover">
              <p className="flex h-full flex-col justify-center text-center font-bold text-white">
                {user?.displayName
                  ? getInitials(user?.displayName)
                  : user?.email?.[0]?.toUpperCase()}
              </p>
            </div>
          )}
          <div className="">
            <h3 className="mb-0.5 max-w-[110px] overflow-hidden text-ellipsis text-sm font-medium leading-6 text-slate-800">
              {user?.displayName || user?.email}
            </h3>
            {isOrganisationNameLoading && (
              <div className="mt-2 h-3 w-10 animate-pulse rounded-xl bg-slate-200 " />
            )}
            {!isOrganisationNameLoading && (
              <p className="text-light-300 max-w-[110px] overflow-hidden text-ellipsis text-xs font-medium leading-5">
                {toCapUpperCase(organisationName ?? '')}
              </p>
            )}
          </div>
        </div>

        <button
          className="hover:bg-primary-light rounded p-1 transition-all duration-200"
          onClick={handleThreeDotsClick}
        >
          <ThreeDotsIcon />
        </button>
      </div>
    </div>
  )
}
