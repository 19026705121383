import { CandidateBackground, BackgroundKind } from '@prisma/client'

export const getSortedBackground = (
  background: CandidateBackground[] | undefined,
  kind: BackgroundKind,
) => {
  if (!background) {
    return
  }
  return background
    .filter(b => b.kind === kind)
    .sort((a, b) => Number(a.order) - Number(b.order))
}

export const getInitials = (name: string | null | undefined): string =>
  String(name)
    .replace(/\s+/, ' ')
    .split(' ')
    .slice(0, 2)
    .map(v => v && v[0].toUpperCase())
    .join('')

export const shorten = (
  str: string | null | undefined,
  maxLength: number,
): string => {
  const trueString = String(str)

  if (trueString.length <= maxLength) {
    return trueString
  }
  return trueString.slice(0, maxLength - 3) + '...'
}
