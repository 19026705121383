import type { FC } from 'react'

const StarIcon: FC<{ className: string }> = props => {
  const { className } = props
  return (
    <svg
      width={24}
      height={24}
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.95751 20.8288C7.47732 21.0933 6.89639 21.0467 6.45958 20.7087C6.02276 20.3707 5.80604 19.8 5.90074 19.2371L6.66717 14.6012L3.44038 11.338C3.04597 10.941 2.90142 10.341 3.06831 9.7935C3.2352 9.24598 3.68413 8.84741 4.22387 8.76758L8.70405 8.09174L10.7267 3.83373C10.9665 3.32307 11.4599 3 12 3C12.5401 3 13.0335 3.32307 13.2733 3.83373L15.2959 8.09174L19.7761 8.76758C20.3159 8.84741 20.7648 9.24598 20.9317 9.7935C21.0986 10.341 20.954 10.941 20.5596 11.338L17.3328 14.6012L18.0993 19.2381C18.194 19.801 17.9772 20.3717 17.5404 20.7097C17.1036 21.0477 16.5227 21.0943 16.0425 20.8298L12 18.6253L7.95751 20.8288Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default StarIcon
