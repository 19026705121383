import type { FC } from 'react'

interface UpIconProps {
  className?: string
}

const UpIcon: FC<UpIconProps> = props => {
  const { className } = props
  return (
    <svg
      className={className}
      viewBox="0 0 8 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.33268 3.66666L3.99935 0.333324L0.666016 3.66666"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default UpIcon
