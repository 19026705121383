import type { FC } from 'react'

const KeyboardIcon: FC<{ className?: string }> = props => {
  const { className } = props
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9194 18H6.08137C4.79437 18 3.75037 16.957 3.75037 15.669V8.331C3.75037 7.043 4.79337 6 6.08137 6H17.9204C19.2074 6 20.2514 7.043 20.2514 8.331V15.67C20.2504 16.957 19.2074 18 17.9194 18Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.9104 10.545C17.0104 10.545 17.0924 10.464 17.0914 10.363C17.0914 10.263 17.0104 10.181 16.9094 10.181C16.8084 10.181 16.7274 10.262 16.7274 10.363C16.7274 10.464 16.8094 10.545 16.9104 10.545"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.9104 13.818C17.0104 13.818 17.0924 13.737 17.0914 13.636C17.0914 13.536 17.0104 13.454 16.9094 13.454C16.8084 13.454 16.7274 13.535 16.7274 13.636C16.7274 13.737 16.8094 13.818 16.9104 13.818"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.2303 13.64H13.7703"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.6373 10.545C13.7373 10.545 13.8193 10.464 13.8183 10.363C13.8183 10.263 13.7373 10.181 13.6363 10.181C13.5363 10.181 13.4543 10.262 13.4543 10.363C13.4553 10.464 13.5363 10.545 13.6373 10.545"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.3644 10.545C10.4644 10.545 10.5464 10.464 10.5454 10.363C10.5454 10.263 10.4644 10.181 10.3634 10.181C10.2634 10.181 10.1814 10.262 10.1814 10.363C10.1824 10.464 10.2634 10.545 10.3644 10.545"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.09243 10.545C7.19243 10.545 7.27443 10.464 7.27343 10.363C7.27343 10.263 7.19243 10.181 7.09143 10.181C6.99043 10.181 6.90943 10.262 6.90943 10.363C6.90943 10.464 6.99143 10.545 7.09243 10.545"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.09243 13.818C7.19243 13.818 7.27443 13.737 7.27343 13.636C7.27343 13.536 7.19243 13.454 7.09143 13.454C6.99043 13.454 6.90943 13.535 6.90943 13.636C6.90943 13.737 6.99143 13.818 7.09243 13.818"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default KeyboardIcon
