import type { FC } from 'react'

const PeopleIcon: FC<{ className: string }> = props => {
  const { className } = props
  return (
    <svg
      className={className}
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.9 7.6c.8.8.8 2 0 2.8-.8.8-2 .8-2.8 0-.8-.8-.8-2 0-2.8.8-.8 2-.8 2.8 0M17.8 6.7c1 1 1 2.6 0 3.5-1 .9-2.6 1-3.5 0-.9-1-1-2.6 0-3.5.9-.9 2.5-.9 3.5 0M20.5 18v-1c0-1.7-1.3-3-3-3h-3c-1.7 0-3 1.3-3 3v1M8.5 14h-2c-1.7 0-3 1.3-3 3v1"
        stroke="#94A3B8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default PeopleIcon
