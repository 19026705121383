export const toCapLowerCase = (str: string) =>
  str
    .split('_')
    .map(word => word.charAt(0) + word.slice(1).toLocaleLowerCase())
    .join(' ')

export const toCapUpperCase = (str: string) =>
  str
    .split('_')
    .map(word => word.charAt(0).toLocaleUpperCase() + word.slice(1))
    .join(' ')
