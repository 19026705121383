import { NavigationSectionProps } from '@/@types/navigation'
import { usePendingReviewsQuery } from '@/hooks/usePendingReviewsQuery'
import { classNames } from '@/utils/classNames'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { trpc } from '@utils/trpc'
import { useState } from 'react'
import { BriefcaseIcon, OverviewIcon, SettingsIcon, StarIcon } from '../icons'
import { NavigationSection } from './NavigationSection'
import { Profile } from './Profile'
const topSections: NavigationSectionProps[] = [
  {
    title: 'Insights',
    items: [
      {
        title: 'Overview',
        path: '/dashboard',
        icon: OverviewIcon,
      },
    ],
  },
  {
    title: 'Find',
    items: [
      {
        title: 'Jobs',
        path: '/',
        icon: BriefcaseIcon,
      },
      {
        title: 'Review',
        path: '/reviews',
        icon: StarIcon,
        count: '0',
      },
    ],
  },
]

const bottomSections = [
  {
    title: '',
    items: [
      {
        title: 'Settings',
        path: '/settings',
        icon: SettingsIcon,
      },
    ],
  },
]

export const Navigation = () => {
  const [open, setOpen] = useState<boolean>(false)
  const { totalPending } = usePendingReviewsQuery()
  const { data: products, isLoading: isProductsLoading } = trpc.useQuery(
    ['product.byUID'],
    {
      refetchInterval: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
      retryOnMount: false,
      context: {
        skipBatch: true,
      },
    },
  )

  return (
    <aside className="md:w-64">
      <div className="relative left-0 top-0 flex flex-col overflow-auto bg-white py-6 px-4 drop-shadow-sm md:fixed md:h-screen md:w-64">
        {/* [LOGO] */}
        <div className="relative">
          <a href="#" className="mx-auto block h-auto w-24 pr-2 md:mx-0">
            <img
              src="/images/logo.svg"
              alt="Logo"
              className="h-auto w-full object-contain"
            />
          </a>
          <button
            className="group absolute left-2 top-1/2 -translate-y-1/2 transform md:hidden"
            onClick={() => setOpen(prevState => !prevState)}
            {...{ open }}
          >
            <MenuIcon className="block h-5 w-5 group-open:hidden" />
            <XIcon className="hidden h-5 w-5 group-open:block" />
          </button>
        </div>
        <div className={classNames(open ? 'nav-active' : '', 'nav-toggle')}>
          <hr className="border-light-100 my-6 mx-2 border border-dashed" />
          {isProductsLoading && (
            <div className="mt-7 space-y-4">
              <div className="w-3 animate-pulse rounded-md bg-slate-200 py-1.5 px-5" />
              <div className="animate-pulse rounded-3xl bg-slate-200 py-5 px-5" />
              <div className="animate-pulse rounded-3xl bg-slate-200 py-5 px-5" />
            </div>
          )}
          {/* [SIDEBAR LIST] */}
          {!isProductsLoading &&
            topSections.map((section, sectionIdx) => {
              if (
                !(products?.atsIntegration || products?.hrisIntegration) &&
                section.title === 'Insights'
              )
                return
              if (!products?.sourceAndScreen && section.title === 'Find') return

              section.items.map(i =>
                i.path === '/reviews' ? (i.count = String(totalPending)) : i,
              )
              return <NavigationSection key={sectionIdx} {...section} />
            })}
          <div className="block md:hidden">
            <hr className="border-light-100 mx-2 mb-4 -mt-2 border border-dashed" />
            {bottomSections.map((section, sectionIdx) => (
              <NavigationSection key={sectionIdx} {...section} />
            ))}
            <hr className="border-light-100 mx-2 mb-4 -mt-2 border border-dashed" />
            <Profile />
          </div>
        </div>
        <div className="mt-auto hidden md:block">
          {bottomSections.map((section, sectionIdx) => (
            <NavigationSection key={sectionIdx} {...section} />
          ))}

          <hr className="border-light-100 mx-2 mb-6 -mt-2 border border-dashed" />
          <Profile />
        </div>
      </div>
    </aside>
  )
}
