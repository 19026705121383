import type { FC } from 'react'

interface ChevronDownIconProps {
  className: string
}

export const ChevronDownIcon: FC<ChevronDownIconProps> = props => {
  const { className } = props
  return (
    <svg
      id="dropdown-arrow-0"
      className={className}
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m8 10 4 4 4-4"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ChevronDownIcon

//stroke-current text-slate-400
