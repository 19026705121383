import { trpc } from '@/utils/trpc'

export const usePendingReviewsQuery = () => {
  const { data: stats, isLoading } = trpc.useQuery(
    ['outbound.amountOfUnreviewedOutboundsByUser'],
    {
      refetchInterval: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 30 * 60 * 1000,
      context: {
        skipBatch: true,
      },
    },
  )

  const totalPending = stats
    ? stats.reduce((acc, stat) => acc + stat.pendingNumber, 0)
    : 0

  return { stats, isLoading, totalPending }
}
