export const BrowserWindowIcon = () => {
  return (
    <svg
      width="152"
      height="147"
      viewBox="0 0 152 147"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M74.2017 128.241C104.077 128.241 128.298 104.02 128.298 74.0377C128.298 44.0553 103.971 19.8347 74.2017 19.8347C44.3261 19.8347 20.1055 44.0553 20.1055 74.0377C20.1055 104.02 44.3261 128.241 74.2017 128.241Z"
        fill="#EAEEF9"
      />
      <path
        d="M22.9876 38.3997C24.6375 38.3997 25.9751 37.0622 25.9751 35.4122C25.9751 33.7622 24.6375 32.4246 22.9876 32.4246C21.3376 32.4246 20 33.7622 20 35.4122C20 37.0622 21.3376 38.3997 22.9876 38.3997Z"
        fill="#E7EBF6"
      />
      <g filter="url(#filter0_d_1215_11168)">
        <path
          d="M125.752 45.5668V108.346C125.752 110.188 124.217 111.723 122.375 111.723H26.9784C25.1365 111.799 23.6016 110.265 23.6016 108.423V45.5668C23.6016 43.6481 25.1365 42.1899 26.9784 42.1899H122.375C124.294 42.1899 125.752 43.7249 125.752 45.5668Z"
          fill="url(#paint0_linear_1215_11168)"
        />
      </g>
      <path
        d="M125.752 45.5668V50.4019H23.6016V45.5668C23.6016 43.6481 25.1365 42.1899 26.9784 42.1899H122.375C124.294 42.1899 125.752 43.7249 125.752 45.5668Z"
        fill="url(#paint1_linear_1215_11168)"
      />
      <path
        d="M60.5916 74.7307C62.1599 74.7307 63.4312 73.4594 63.4312 71.8911C63.4312 70.3228 62.1599 69.0515 60.5916 69.0515C59.0233 69.0515 57.752 70.3228 57.752 71.8911C57.752 73.4594 59.0233 74.7307 60.5916 74.7307Z"
        fill="#989FB0"
      />
      <path
        d="M88.8357 74.7307C90.404 74.7307 91.6754 73.4594 91.6754 71.8911C91.6754 70.3228 90.404 69.0515 88.8357 69.0515C87.2674 69.0515 85.9961 70.3228 85.9961 71.8911C85.9961 73.4594 87.2674 74.7307 88.8357 74.7307Z"
        fill="#989FB0"
      />
      <path
        d="M79.3201 93.3801H70.4174C69.036 93.3801 67.8848 92.2289 67.8848 90.8474C67.8848 89.466 69.036 88.3148 70.4174 88.3148H79.2433C80.6248 88.3148 81.776 89.466 81.776 90.8474C81.8527 92.2289 80.7015 93.3801 79.3201 93.3801Z"
        fill="#989FB0"
      />
      <path
        d="M28.1297 47.7156C28.7655 47.7156 29.2809 47.2002 29.2809 46.5644C29.2809 45.9286 28.7655 45.4132 28.1297 45.4132C27.4939 45.4132 26.9785 45.9286 26.9785 46.5644C26.9785 47.2002 27.4939 47.7156 28.1297 47.7156Z"
        fill="#F9FAFB"
      />
      <path
        d="M31.7371 47.7156C32.3729 47.7156 32.8883 47.2002 32.8883 46.5644C32.8883 45.9286 32.3729 45.4132 31.7371 45.4132C31.1013 45.4132 30.5859 45.9286 30.5859 46.5644C30.5859 47.2002 31.1013 47.7156 31.7371 47.7156Z"
        fill="#F9FAFB"
      />
      <path
        d="M35.4207 47.7156C36.0565 47.7156 36.5719 47.2002 36.5719 46.5644C36.5719 45.9286 36.0565 45.4132 35.4207 45.4132C34.7849 45.4132 34.2695 45.9286 34.2695 46.5644C34.2695 47.2002 34.7849 47.7156 35.4207 47.7156Z"
        fill="#F9FAFB"
      />
      <path
        d="M121.608 34.0547C120.841 34.0547 120.227 33.4407 120.227 32.6732V17.2471C120.227 16.4796 120.841 15.8657 121.608 15.8657C122.375 15.8657 122.989 16.4796 122.989 17.2471V32.6732C122.989 33.4407 122.375 34.0547 121.608 34.0547Z"
        fill="#0D9488"
      />
      <path
        d="M132.812 45.8736C132.812 45.1062 133.426 44.4922 134.194 44.4922H149.62C150.388 44.4922 151.002 45.1062 151.002 45.8736C151.002 46.6411 150.388 47.2551 149.62 47.2551H134.194C133.426 47.2551 132.812 46.6411 132.812 45.8736Z"
        fill="#0D9488"
      />
      <path
        d="M131.585 35.8965C131.047 35.3593 131.047 34.4383 131.585 33.9011L142.559 23.003C143.097 22.4658 144.018 22.4658 144.555 23.003C145.092 23.5402 145.092 24.4612 144.555 24.9984L133.657 35.8965C133.043 36.4337 132.122 36.4337 131.585 35.8965Z"
        fill="#0D9488"
      />
      <defs>
        <filter
          id="filter0_d_1215_11168"
          x="0.127851"
          y="30.4531"
          width="149.098"
          height="116.483"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="11.7369" />
          <feGaussianBlur stdDeviation="11.7369" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1215_11168"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1215_11168"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1215_11168"
          x1="74.6434"
          y1="40.5815"
          x2="74.6434"
          y2="112.475"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FDFEFF" />
          <stop offset="0.9964" stop-color="#ECF0F5" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1215_11168"
          x1="121.7"
          y1="42.19"
          x2="26.8931"
          y2="46.2959"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#B0BACC" />
          <stop offset="1" stop-color="#969EAE" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default BrowserWindowIcon
